import React from "react"
import Layout from "../components/common/layout/layout"
import HeroHeader from "../components/sections/hero-header"

const NotFoundPage = ({ location }) => {
  const pageTitle = "Page Not Found"
  const pageDescription =
    "The page you are looking for doesn't exist or has been moved"
  return (
    <Layout location={location} title={pageTitle} description={pageDescription}>
      <HeroHeader
        title={pageTitle}
        subTitle={pageDescription}
        backgroundImage="notfound-back.png"
        foregroundImage="notfound-front.png"
        middleImage="middle-shape.png"
        actionTitle="Back to homepage"
        actionTo="/"
      />
    </Layout>
  )
}

export default NotFoundPage
